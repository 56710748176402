import 'react-app-polyfill/stable'
import React, { PropsWithChildren } from 'react'
import * as ReactDOM from 'react-dom/client'
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'
import '@css/main.scss'
import { App, set_promoted_app_version } from '~/app'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
  split,
  useApolloClient,
} from '@apollo/client'
import { typePolicies } from '~/apollo'
import { AuthProvider } from '~/auth/Auth'
import { WindowProvider } from '~/common/hooks/useWindowSize'
import { setContext } from '@apollo/client/link/context'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import manifest from '~/api/generated/persisted-query-manifest.json'
import { getApiBase, getAuth, logout, startup } from '~/api/ServerApi'
import {
  GetAuthDocument,
  GetLastKnownUserDocument,
  GetPermissionsDocument,
  PreloadAuthDocument,
} from '~/api/generated/graphql'
import { onError } from '@apollo/client/link/error'
import { logNetworkError } from '~/common/EventLogger'
import { APIProvider } from '@vis.gl/react-google-maps'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import { OperationOptions, SubscriptionClient } from 'subscriptions-transport-ws'
import { ShortcutContextProvider } from '~/contexts/ShortcutContext'
import { RetryLink } from '@apollo/client/link/retry'
import { generatePersistedQueryIdsFromManifest } from '@apollo/persisted-query-lists'
import { Route } from 'react-router'
import { PageVisibilityProvider } from '~/contexts/PageVisibilityContext'
import { Kind, OperationTypeNode } from 'graphql/language'

declare global {
  // eslint-disable-next-line no-var
  var lastKnownUserId: string | undefined
  // eslint-disable-next-line no-var
  var disableWebsocketServer: boolean | undefined
}
const getReadOnlyOps = () => {
  try {
    const data = JSON.parse(document.getElementById('data/READONLY_OPS')?.textContent ?? '[]')
    // true means to send all reads to the RO cluster
    if (data === true) {
      return manifest.operations.filter(o => o.type === 'query').map(o => o.name)
    } else if (data === false) {
      return []
    } else {
      return data
    }
  } catch {
    return []
  }
}

const getSession = () => JSON.parse(document.getElementById('data/SESSION')?.textContent ?? '""')
export const clientFactory = (testing = true) => {
  const errorLink = onError(({ networkError }) => {
    if (networkError) logNetworkError(networkError)
  })

  // when we call location.reload() after logging out a user when their session is lost, we want to prevent the browser
  // from throwing a window alert about any 'unsaved changes'
  window.addEventListener('beforeunload', e => {
    sessionStorage.clear()
    e.stopImmediatePropagation()
  })

  const READONLY_OPS = getReadOnlyOps()

  const getUrlForOperation = (operation: { operationName: string }) => {
    const operationName = operation.operationName
    if (READONLY_OPS.includes(operationName)) {
      return String(new URL('/r/graphql?op=' + operationName, getApiBase()))
    } else {
      return String(new URL('/graphql?op=' + operationName, getApiBase()))
    }
  }

  let httpLink = createHttpLink({
    uri: getUrlForOperation,
    credentials: 'include',
    fetch: (input: RequestInfo | URL, init?: RequestInit) =>
      globalThis.fetch(input, init).then(r => {
        if (r.status === 401) {
          return logout().then(() => {
            location.reload()
            return r
          })
        }
        if (r.headers.has('x-promoted-app-version')) {
          set_promoted_app_version(r.headers.get('x-promoted-app-version') || '')
        }
        return r
      }),
  })

  const authLink = setContext((_, { headers }) => {
    const offset = new Date().getTimezoneOffset()
    return {
      headers: {
        ...headers,
        'x-session-id': getSession(),
        tzOffset: offset,
        tzName: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    }
  })
  const persistLink = createPersistedQueryLink({
    ...generatePersistedQueryIdsFromManifest({ loadManifest: () => manifest }),
    useGETForHashedQueries: false,
  })

  const wsUrl =
    (globalThis.location.protocol === 'https:' ? 'wss://' : 'ws://') + globalThis.location.host + '/subscriptions'

  // https://github.com/apollographql/subscriptions-transport-ws/issues/339#issuecomment-488235741
  let ack = false
  const subscriptionClient = new SubscriptionClient(wsUrl, {
    reconnect: true,
    reconnectionAttempts: 5,
    lazy: true,
    connectionParams: {
      headers: {
        'Sec-WebSocket-Protocol': 'graphql-ws',
      },
    },
    connectionCallback: () => (ack = true),
  })

  subscriptionClient.use([
    {
      applyMiddleware(_: OperationOptions, next: VoidFunction) {
        if (!ack) {
          throw new Error('not ready')
        }
        next()
      },
    },
  ])

  subscriptionClient.onDisconnected(() => (ack = false))

  const wsLink = new WebSocketLink(subscriptionClient)

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: 60000,
      jitter: true,
    },
    attempts: (count, _, e) => {
      if (e && e.response && e.response.status === 401) {
        return false
      }
      return count < 30
    },
  })

  httpLink = testing ? authLink.concat(httpLink) : persistLink.concat(authLink.concat(httpLink))

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION
    },
    wsLink,
    httpLink
  )

  return new ApolloClient({
    link: from([errorLink, retryLink, splitLink]),
    cache: new InMemoryCache({
      typePolicies,
    }),
    credentials: 'include',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
      },
    },
  })
}

export const PreloadAuth = ({ children }: PropsWithChildren) => {
  const { cache } = useApolloClient()
  const authData = getAuth()
  if (authData) {
    const company = { ...authData.company, __typename: 'Company' as const }
    const { permissions, ...user } = authData

    const perms = {
      ...(permissions ?? { canPostIds: [], canEditIds: [], sysAdminId: '', relAdminId: '', summitAdminId: '' }),
      __typename: 'Permissions' as const,
    }
    globalThis.disableWebsocketServer = authData['disableWebsocketServer']
    if (globalThis.disableWebsocketServer) console.log('Websocket server not running. Skipping subscriptions.')
    if (authData['isLastKnownUser']) {
      Object.assign(user, { __typename: 'User' as const })
      globalThis.lastKnownUserId = user.userId
      cache.writeQuery({ query: GetLastKnownUserDocument, data: { user: user }, variables: { id: user.userId } })
      cache.writeQuery({ query: GetAuthDocument, data: { currentUser: null } })
    } else if (user.userId) {
      Object.assign(user, { __typename: 'User' as const, company })
      cache.writeQuery({ query: PreloadAuthDocument, variables: { id: user.userId }, data: { user } })
      cache.writeQuery({ query: GetAuthDocument, data: { currentUser: user } })
      cache.writeQuery({ query: GetPermissionsDocument, data: { permissions: perms } })
    } else {
      cache.writeQuery({ query: GetAuthDocument, data: { currentUser: null } })
    }
  }
  return <>{children}</>
}

const apiKey = JSON.parse(document.getElementById('data/GOOGLE_MAPS_KEY')?.textContent ?? '""')
const libraries = ['places']

const container = document.getElementById('react-main')
if (container) {
  void startup()
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path={'*'}
        element={
          <ApolloProvider client={clientFactory(false)}>
            <PreloadAuth>
              <AuthProvider>
                <WindowProvider>
                  <ShortcutContextProvider>
                    <PageVisibilityProvider>
                      <APIProvider apiKey={apiKey} libraries={libraries}>
                        <App />
                      </APIProvider>
                    </PageVisibilityProvider>
                  </ShortcutContextProvider>
                </WindowProvider>
              </AuthProvider>
            </PreloadAuth>
          </ApolloProvider>
        }
      />
    )
  )

  ReactDOM.createRoot(container).render(<RouterProvider router={router} />)
}
