import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import CommunityLayout from '~/pages/community/CommunityLayout'
import CommunityAbout from './pages/community/CommunityAbout'
import CommunityMembers from '~/pages/community/CommunityMembers'
import CommunityPosts from '~/pages/community/CommunityPosts'
import CommunityPost from '~/pages/community/CommunityPost'
import CommunityListLayout, { CommunityListTab } from '~/pages/community/CommunityListLayout'
import { Login } from '~/pages/Login'
import { Privacy } from '~/pages/Privacy'
import { Terms } from '~/pages/Terms'
import { RequireAuth } from '~/auth/Auth'
import CommunityEvents from '~/pages/community/CommunityEvents'
import CommunityContent from '~/pages/community/CommunityContent'
import { ProfileLayout } from '~/pages/profile/ProfileLayout'
import Version from '~/pages/Version'
import { ToastContainer } from 'react-bootstrap'
import CompanyLayout from '~/pages/company/CompanyLayout'
import CompanyAccountPartners from '~/pages/company/CompanyAccountPartners'
import CompanyHome from '~/pages/company/CompanyHome'
import HomeFeed, { HomeFeedTab } from '~/pages/feed/HomeFeed'
import AdminLayout from '~/pages/admin/AdminLayout'
import SettingsLayout from '~/pages/profile/SettingsLayout'
import CommunityHistory from '~/pages/community/CommunityHistory'
import { useLocation } from 'react-router-dom'
import CustomerActivity from '~/pages/company/CustomerActivity'
import { getApiBase } from '~/api/ServerApi'
import CommunitySettings from '~/pages/community/CommunitySettings'
import AddCustomer from '~/pages/company/AddCustomer'
import { RequireCommunities } from '~/contexts/CommunitiesContext'
import PostRepostOverlay from '~/pages/posts/PostRepostOverlay'
import EventRepostOverlay from '~/pages/community/EventRepostOverlay'
import { RequireTypeahead } from '~/contexts/TypeaheadContext'
import PageLayout from '~/pages/page/PageLayout'
import AddMenuPageWrapper from '~/pages/page/AddPage'
import SectionMenu from '~/pages/page/SectionMenu'
import { AdvancedSearch } from '~/pages/search/AdvancedSearch'
import { PageType } from '~/api/generated/graphql'
import { AuthStatus } from '~/api/types'
import { LocalStorageKey } from '~/types'

let PROMOTED_APP_VERSION = ''
export const set_promoted_app_version = (version: string) => (PROMOTED_APP_VERSION = version)
export const App = () => {
  const API_BASE = getApiBase()
  const APP_VERSION = JSON.parse(document.getElementById('data/VERSION')?.textContent ?? '0')
  const API_HOSTNAME = API_BASE ? new URL(API_BASE).hostname : ''
  const summitPaths = ['summit', 'summits']

  const location = useLocation()

  // Keep track of the previous path in order to check if we just came from /communities/* to persist scroll height in the communities list
  useEffect(() => {
    let from = localStorage.getItem(LocalStorageKey.current) ?? ''
    let current = location.pathname

    const listNames = new Set(['/communities', '/mycommunities', '/allcommunities'])

    // We want to treat /about /posts etc. pages to all be just /communities/id/
    const fromMatch = from.match(/^\/(communities|companies)\/[^/]+/)
    from = fromMatch ? (fromMatch[0] ?? from) : from
    const currentMatch = current.match(/^\/(communities|companies)\/[^/]+/)
    current = currentMatch ? (currentMatch[0] ?? current) : current

    if (!(currentMatch && currentMatch[0] && from === current)) {
      localStorage.setItem(LocalStorageKey.from, from)
      localStorage.setItem(LocalStorageKey.current, current)

      // If we aren't going between the list / community pages then we can reset the scroll/pagination state
      if (!(fromMatch && fromMatch[0] && listNames.has(current)) && !(currentMatch && listNames.has(from))) {
        localStorage.setItem(LocalStorageKey.displayedCountMine, '25')
        localStorage.setItem(LocalStorageKey.displayedCountAll, '25')
        localStorage.setItem(LocalStorageKey.communitiesScrollHeight, '0')
      }
    }
  }, [location.pathname])

  useEffect(() => {
    if (
      PROMOTED_APP_VERSION &&
      PROMOTED_APP_VERSION > APP_VERSION &&
      API_HOSTNAME &&
      API_HOSTNAME.toLowerCase() !== globalThis.location.hostname.toLowerCase()
    ) {
      globalThis.location.reload()
    }
    // We want to preserve scroll position on release/summit pages.
    // We also don't want to scroll if we are scrolling to a comment or event
    if (
      !(
        location.pathname.includes('release') ||
        location.pathname.includes('summits') ||
        location.pathname.includes('search') ||
        location.pathname === '/communities' ||
        location.pathname === '/mycommunities' ||
        location.pathname === '/allcommunities' ||
        location.pathname === '/recommendedcommunities' ||
        location.search.includes('c=') ||
        location.search.includes('e=')
      )
    ) {
      document.querySelector('.main')?.scroll(0, 0)
    }
  }, [location, APP_VERSION, API_HOSTNAME])
  return (
    <>
      <Routes>
        <Route path="/">
          <Route path="login">
            <Route index element={<Login initialAuthStatus={AuthStatus.initial} />} />
            <Route path="complete" element={<Login showCompleteProfile={true} />} />
            <Route path="bad" element={<Login />} />
          </Route>
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route element={<RequireAuth />}>
            <Route element={<RequireTypeahead />}>
              <Route path="admin" element={<AdminLayout />} />
              <Route path="addcustomer" element={<AddCustomer />} />
              <Route element={<RequireCommunities />}>
                <Route index element={<HomeFeed />} />
                <Route path="feed" element={<HomeFeed />} />
                <Route path="myfeed" element={<HomeFeed homeFeedTab={HomeFeedTab.My} />} />
                <Route path="followingfeed" element={<HomeFeed homeFeedTab={HomeFeedTab.Following} />} />
                <Route path="recommendedcommunities">
                  <Route index element={<CommunityListLayout communityListTab={CommunityListTab.Recommended} />} />
                </Route>
                <Route path="mycommunities">
                  <Route index element={<CommunityListLayout communityListTab={CommunityListTab.My} />} />
                </Route>
                <Route path="allcommunities">
                  <Route index element={<CommunityListLayout communityListTab={CommunityListTab.All} />} />
                </Route>
                <Route path="communities">
                  <Route index element={<CommunityListLayout />} />
                  <Route path=":communityId">
                    <Route element={<CommunityLayout />}>
                      <Route index element={<Navigate to="about" replace />} />
                      <Route path="about" element={<CommunityAbout />} />
                      <Route path="posts">
                        <Route index element={<CommunityPosts />} />
                        <Route path=":postId">
                          <Route index element={<CommunityPost fromContentPage={false} />} />
                          <Route path="repost">
                            <Route index element={<PostRepostOverlay fromContentPage={false} />} />
                          </Route>
                        </Route>
                      </Route>
                      <Route path="members" element={<CommunityMembers isCompany={false} />} />
                      <Route path="events">
                        <Route index element={<CommunityEvents isCompany={false} />} />
                        <Route path=":eventId">
                          <Route path="repost">
                            <Route index element={<EventRepostOverlay />} />
                          </Route>
                        </Route>
                      </Route>
                      <Route path="content">
                        <Route index element={<CommunityContent />} />
                        <Route path=":postId">
                          <Route index element={<CommunityPost fromContentPage={true} />} />
                          <Route path="repost">
                            <Route index element={<PostRepostOverlay fromContentPage={true} />} />
                          </Route>
                        </Route>
                      </Route>
                      <Route path="settings" element={<CommunitySettings isCompany={false} />} />
                      <Route path="history" element={<CommunityHistory isCompany={false} />} />
                    </Route>
                  </Route>
                </Route>
                <Route path="profiles">
                  <Route path=":userId" element={<ProfileLayout isEditing={false} />} />
                  <Route path=":userId">
                    <Route path="edit" element={<ProfileLayout isEditing={true} />} />
                  </Route>
                </Route>
                <Route path="edit-my-profile" element={<ProfileLayout isEditing={true} sendToProfile={true} />}></Route>
                <Route path="search" element={<AdvancedSearch />} />
                <Route path="companies">
                  <Route path=":companyId" element={<CompanyLayout />}>
                    <Route index element={<Navigate to={'home'} replace />} />
                    <Route path="home" element={<CompanyHome />} />
                    <Route path="posts">
                      <Route index element={<CommunityPosts />} />
                      <Route path=":postId" element={<CommunityPost fromContentPage={false} />} />
                    </Route>
                    <Route path="comments">
                      <Route index element={<CommunityPosts />} />
                      <Route path=":commentId" element={<CommunityPost fromContentPage={false} />} />
                    </Route>
                    <Route path="content">
                      <Route index element={<CommunityContent />} />
                      <Route path=":postId" element={<CommunityPost fromContentPage={true} />} />
                    </Route>
                    <Route path="partners" element={<CompanyAccountPartners />} />
                    <Route path="members" element={<CommunityMembers isCompany={true} />} />
                    <Route path="events" element={<CommunityEvents isCompany={true} />} />
                    <Route path="settings" element={<CommunitySettings isCompany={true} />} />
                    <Route path="history" element={<CommunityHistory isCompany={true} />} />
                    <Route path="activity" element={<CustomerActivity />} />
                  </Route>
                </Route>
                <Route path="releases">
                  <Route path=":releaseId" element={<PageLayout pageType={PageType.Release} />} />
                </Route>
                {summitPaths.map(s => (
                  <Route path={s} key={s}>
                    <Route path=":releaseId" element={<PageLayout pageType={PageType.Summit} />} />
                  </Route>
                ))}
              </Route>
              <Route
                path="addreleasepage"
                element={<AddMenuPageWrapper isEditingPage={false} pageType={PageType.Release} />}
              />
              <Route path="releases">
                <Route path=":releaseId">
                  <Route
                    path="edit"
                    element={<AddMenuPageWrapper isEditingPage={true} pageType={PageType.Release} />}
                  />
                </Route>
              </Route>
              <Route path="releasesmenu" element={<SectionMenu pageType={PageType.Release} />} />
              <Route
                path="addsummitpage"
                element={<AddMenuPageWrapper isEditingPage={false} pageType={PageType.Summit} />}
              />
              {summitPaths.map(s => (
                <Route path={s} key={s}>
                  <Route path=":releaseId">
                    <Route
                      path="edit"
                      element={<AddMenuPageWrapper isEditingPage={true} pageType={PageType.Summit} />}
                    />
                  </Route>
                </Route>
              ))}
              <Route path="summitsmenu" element={<SectionMenu pageType={PageType.Summit} />} />
              <Route path="settings" element={<SettingsLayout />} />
            </Route>
          </Route>
          <Route path="version" element={<Version />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
      <ToastContainer position="top-end" id="toast-container" />
    </>
  )
}
