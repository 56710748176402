import React, { useCallback, useEffect, useState } from 'react'
import { GetMediaComponentUserDocument, Maybe, MediaType } from '~/api/generated/graphql'
import { useAuth } from '~/auth/Auth'
import { contentIconSrc, createURLwithQueryParams, getWistiaId, secondsToTimeDisplay, updateWistiaLink } from '~/utils'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import '@css/pages/posts/MediaComponent.scss'
import { elementClicked } from '~/common/EventLogger'
import { useCommentLoadingMedia } from '~/contexts/CommentLoadingMediaContext'
import { Spinner } from 'react-bootstrap'
import { useQuery } from '@apollo/client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const wistiaEmbeds: any[]
declare global {
  // eslint-disable-next-line no-var
  var includedWistia: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-var
  var _wq: any
}

type Props = {
  media_type?: MediaType
  url?: string
  content_title?: string
  post_id?: string
  uploading?: boolean
  index?: number
  comment_id?: string
  thumbnail_progress?: number
}
const WISTIA_BASE_URL = 'https://fast.wistia.com'

declare const Wistia: { api: (id?: string) => { remove: () => void; play: () => void; time: (val: number) => void } }

export const MediaComponent = ({
  media_type,
  url,
  content_title,
  post_id,
  comment_id,
  uploading,
  index,
  thumbnail_progress,
}: Props) => {
  const { email, authUserId } = useAuth()
  const { data: userData } = useQuery(GetMediaComponentUserDocument, {
    variables: { id: authUserId ?? '' },
    skip: !authUserId,
  })
  const { windowSize } = useWindowSize()
  const wistiaId = url?.match(/(?<wistiaId>\w+)\.jsonp/)?.groups?.wistiaId
  const { onMediaLoaded } = useCommentLoadingMedia()

  const setMediaLoaded = () => {
    onMediaLoaded?.(index ?? undefined)
  }

  useEffect(() => {
    if (media_type == MediaType.Video && url) {
      let pathname = ''
      try {
        pathname = url.startsWith(WISTIA_BASE_URL) ? new URL(url).pathname : url
      } catch {
        /* IGNORE */
      }
      if (!(globalThis as never)[`wistiajsonp-${pathname}`]) {
        const jsonp_include = document.createElement('script')
        jsonp_include.setAttribute('src', url)
        jsonp_include.setAttribute('async', 'true')
        document.body.append(jsonp_include)
      }
      if (!globalThis.includedWistia) {
        try {
          for (const e of wistiaEmbeds) e.popover.hide()
        } catch {
          /* IGNORE */
        }
        globalThis.includedWistia = true
        const jsonp_include = document.createElement('script')
        jsonp_include.setAttribute('src', 'https://fast.wistia.com/assets/external/E-v1.js')
        jsonp_include.setAttribute('async', 'true')
        document.body.append(jsonp_include)
      }
    }
  }, [media_type, url, wistiaId])

  const clickVideoPlayed = (e: React.MouseEvent) => {
    if (comment_id) {
      elementClicked(e, 'click-comment-video-played', { commentId: comment_id, postId: post_id })
    } else if (post_id) {
      elementClicked(e, 'click-video-played', { postId: post_id })
    }
  }

  const clickFileDownloaded = (e: React.MouseEvent) => {
    if (comment_id) {
      elementClicked(e, 'click-comment-file-downloaded', { commentId: comment_id, postId: post_id })
    } else if (post_id) {
      elementClicked(e, 'click-post-file-downloaded', { postId: post_id })
    }
  }

  switch (media_type) {
    case MediaType.Image:
      if (url)
        return (
          <>
            <a href={url} target={'_blank'} onLoad={setMediaLoaded}>
              <img src={url} alt={''} className={'image'} data-testid={'media-image'} />
            </a>
          </>
        )
      else return <></>
    case MediaType.Video: {
      return (
        <VideoContent
          url={url}
          post_id={post_id ?? ''}
          comment_id={comment_id}
          onLoad={setMediaLoaded}
          thumbnailProgress={thumbnail_progress}
        />
      )
    }
    case MediaType.Brainshark: {
      const src = createURLwithQueryParams(url ?? '', [
        { key: 'b', value: '1' },
        { key: 'em', value: email ?? '' },
        { key: 'fn', value: userData?.user?.firstName ?? '' },
        { key: 'ln', value: userData?.user?.lastName ?? '' },
        { key: 'cn', value: userData?.user?.company?.name ?? '' },
      ])
      const height = Math.min(windowSize.height * 0.5, 700)
      return (
        <iframe
          src={src}
          allowFullScreen={true}
          width={'100%'}
          height={height}
          onClick={clickVideoPlayed}
          onLoad={setMediaLoaded}
        />
      )
    }
    default:
      return uploading ? (
        <img
          alt="Content Icon"
          className={uploading ? 'uploading' : ''}
          src={contentIconSrc(media_type)}
          tabIndex={-1}
        />
      ) : (
        <>
          <a download={content_title} href={url} onClick={clickFileDownloaded} data-testid={'media-file'} tabIndex={-1}>
            <img
              alt="Content Icon"
              className={uploading ? 'uploading' : ''}
              src={contentIconSrc(media_type)}
              onLoad={setMediaLoaded}
              tabIndex={-1}
            />
          </a>
          <p>
            <a download={content_title} href={url} onClick={clickFileDownloaded}>
              {content_title}
            </a>
          </p>
        </>
      )
  }
}

export const VideoContent = ({
  url,
  post_id,
  comment_id,
  isInlineWrapped,
  onLoad,
  playButton,
  startTime,
  thumbnailProgress,
}: {
  url: string | undefined
  post_id: string
  comment_id?: string
  isInlineWrapped?: boolean
  onLoad?: () => void
  playButton?: JSX.Element
  startTime?: number
  thumbnailProgress?: number
}) => {
  const wistiaUrl = updateWistiaLink(url ?? null)
  const wistiaId = getWistiaId(wistiaUrl)
  const { isCondensed: condensed } = useWindowSize()
  const isCondensed = condensed && !isInlineWrapped
  const [duration, setDuration] = useState<number>()
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('')
  const [playingVideo, setPlayingVideo] = useState(false)
  const [hidePopover, setHidePopover] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // We want to wait for the popover to finish its "hide" animation before destroying the element, otherwise it logs an uncaught TypeError
    if (!playingVideo && !loading) {
      const retry = () => {
        if (
          (document.getElementsByClassName(`wistia_embed wistia_async_${wistiaId}`)[0] as HTMLElement)?.style
            .visibility === 'hidden' ||
          (document.getElementsByClassName(`wistia_embed wistia_async_${wistiaId}`)[0] as HTMLElement) === undefined
        ) {
          setHidePopover(true)
        } else {
          retry()
        }
      }
      const timer = setTimeout(() => {
        retry()
      }, 5)
      return () => clearTimeout(timer)
    }
  }, [playingVideo, loading, wistiaId])

  const pushWistia = useCallback(() => {
    const a = {
      id: `${wistiaId}`,
      onHasData: function () {
        Wistia.api(wistiaId).time(startTime ?? 0)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onReady: function (video: any) {
        video.bind('popovershow', function () {
          setLoading(false)
          globalThis.addEventListener('popstate', () => {
            video.popover.hide()
          })
        })
        video.bind('popoverhide', function () {
          setPlayingVideo(false)
        })

        Wistia.api(wistiaId).play()
      },
    }
    globalThis._wq = globalThis._wq || []
    globalThis._wq.push(a)
  }, [wistiaId, startTime])

  useEffect(() => {
    void fetch(`https://fast.wistia.net/oembed?url=http://home.wistia.com/medias/${wistiaId}?embedType=async`)
      .then(resp => resp.json())
      .then(json => {
        setThumbnailUrl(json.thumbnail_url)
      })
    // if the thumbnail progress re-uploads we need to re-trigger fetching the thumbnail url
  }, [wistiaId, thumbnailProgress])

  useEffect(() => {
    void fetch(`https://fast.wistia.com/embed/medias/${wistiaId}.json`)
      .then(resp => resp.json())
      .then(json => setDuration(json.media.duration))
  }, [wistiaId])

  const handlePlayClick = (e: React.MouseEvent) => {
    setLoading(true)
    setPlayingVideo(true)
    setHidePopover(false)
    if (comment_id) {
      elementClicked(e, 'click-comment-video-played', { commentId: comment_id, postId: post_id })
    } else {
      elementClicked(e, 'click-video-played', { postId: post_id })
    }
  }

  return (
    // We need to change the key whenever the wistiaId changes to force React to completely replace the
    // embedded player, otherwise it will not notice the new video id.
    <React.Fragment key={wistiaId}>
      {(thumbnailUrl !== '' || Boolean(startTime)) &&
        (hidePopover ? (
          <>
            {playButton ? (
              // wrap the passed in playButton in a span which contains the click handler
              <span onClick={handlePlayClick}>{playButton}</span>
            ) : (
              <>
                <img
                  className={'thumbnail'}
                  src={`${thumbnailUrl}&image_play_button=true&image_play_button_color=F8991C`}
                  onClick={handlePlayClick}
                  onLoad={onLoad}
                  data-testid={'media-video'}
                />
                {!isCondensed && <div className={'duration_text'}>{secondsToTimeDisplay(duration)}</div>}
              </>
            )}
          </>
        ) : (
          <>
            {playButton || <img className={'thumbnail'} src={thumbnailUrl} data-testid={'media-video'} />}
            {loading && !playButton && (
              <div className={'spinner'}>
                <Spinner animation={'border'} role={'status'} style={{ width: '100%', height: '100%' }}>
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
            <PopOverPlay wistiaId={wistiaId ?? ''} pushWistia={pushWistia} parent_id={comment_id || post_id} />
          </>
        ))}
    </React.Fragment>
  )
}

export const PopOverPlay = ({
  wistiaId,
  pushWistia,
  parent_id,
}: {
  wistiaId: string
  pushWistia: () => void
  parent_id?: string
}) => {
  const { authUserId, email } = useAuth()
  useEffect(() => {
    pushWistia()
    return () => {
      globalThis._wq.push({
        revoke: {
          id: `${wistiaId}`,
        },
      })
      Wistia.api(wistiaId)?.remove()
    }
  }, [pushWistia, wistiaId])
  return (
    <div
      className={`wistia_embed wistia_async_${wistiaId} popover=true seo=false email=${format_view(
        email ?? null,
        authUserId ?? null,
        parent_id ?? null
      )}`}
      style={{ visibility: 'hidden' }}
      data-testid={`wistia-popover-${wistiaId}`}
    ></div>
  )
}

export const format_view = (email: Maybe<string>, userId: Maybe<string>, postId: Maybe<string>) =>
  `${userId}@${email?.split('@')[1]}\\${postId}`
